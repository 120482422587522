import React from 'react';
import Layout from '../../components/layout/Layout';

const App = () => {
  return (
    <Layout>
      <div className='flex-grow'>Hello world!</div>
    </Layout>
  );
};
export default App;
